import React from 'react';

import { useScroll } from '../hooks/useScroll'

// Import images and icons
// import backgroundImage from "./components/Images/backgroundImageCrop.jpg";
import { Button, Container, Navbar, Nav, Form, Row, Col } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';

export default function MainNavbar({ userFirstName, userLastName }) {
    const { y, x, scrollDirection } = useScroll();

    const styles = {
        active: {
            visibility: "visible",
            transition: "all 0.5s"
        },
        hidden: {
            visibility: "hidden",
            transition: "all 0.5s",
            transform: "translateY(-100%)"
        }
    }

    const leftPad = {
        marginLeft: '20px',
    };
    const rightPad = {
        marginRight: '20px',
    };
    const bothPad = {
        marginLeft: '20px',
        marginRight: '20px',
    };
    const topPad8 = {
        marginTop: '5px',
    };
    const topPad = {
        marginTop: '20px',
    };
    const buttonStyle = {
        marginTop: '17px',
        marginRight: '17px',
        fontSize: '0.9rem'
    };

    return (
        <div>
            <Navbar expand="sm" bg="primary">
                {userFirstName == "EKD User" && (
                    <Navbar.Brand href="/browse" style={leftPad}>
                        <Row>
                            <Col>
                                <h3 className="text-light shadow-text" style={topPad}>Cerebro</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Anews">News</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Amas">MAS</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdfood">Food</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdlifestyle">Lifestyle</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdpersonalfinance">Personal Finance</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Abookreviews">Book Reviews</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdsports">Sports</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Apersonalgrowth">Personal Growth</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdtravel">Travel</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdvideogaming">Video Gaming</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdparenting">Parenting</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdgadgets">Gadgets</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Agenai">GenAI</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aai">AI</Button>
                            </Col>
                        </Row>
                    </Navbar.Brand>
                )}
                {userFirstName != "EKD User" && (
                    <Navbar.Brand href="/browse" style={leftPad}>
                        <Row>
                            <Col>
                                <h3 className="text-light shadow-text" style={topPad}>Cerebro</h3>
                            </Col>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Anews">News</Button>
                                <Button style={buttonStyle} variant="secondary" href='/?query=topic%3Adigests'>Digests</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Acurated">Curated</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Amas">MAS</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Afinance">Finance</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Atech">Tech</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Adata">Data</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Asustainability">Sustainability</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Agenai">GenAI</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aeconomist">Economist</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Athinkers">Thinkers</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Adev">Dev</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aseo">SEO</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Ainvesting">Investing</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Areits">REITs</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aparenting">Parenting</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Acooking">Cooking</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Abaking">Baking</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aproductreviews">Product Reviews</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Afitness">Fitness</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Ahomes">Homes</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Amen">Men</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Awomen">Women</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Atravel">Travel</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Awellness">Wellness</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Atips">Tips</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Adiy">DIY</Button>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aai">AI</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button style={buttonStyle} variant="secondary" href="/?query=topic%3Aekdvideogaming">Video Gaming</Button>
                            </Col>
                        </Row>
                    </Navbar.Brand>
                )}
            </Navbar>
            <Navbar bg="secondary" expand="sm" className="mb-3">
                <Navbar.Collapse style={leftPad}>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end" style={rightPad}>
                    <Navbar.Text className="text-light" style={rightPad}>Welcome, {userFirstName} {userLastName} <FaUserCircle size={28} /></Navbar.Text>
                    {/*<Button variant="light" onClick={handleLogout}><span className="text-primary">Log Out</span></Button>*/}
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}