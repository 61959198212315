import React, { useState, useEffect } from "react";

// Import style sheets
import "../src/style.scss";
import './App.css';

// Import external components
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Import internal components
import api from "./components/api/api";
import Articles from './components/Articles/Articles';
import Login from './components/Login/Login';
import MainNavbar from './components/Navbar/MainNavbar';

function App() {

  const [appState, setAppState] = useState({
    loggedin: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const userDetailRes = await api.getUserDetail();
        setAppState({
          ...appState,
          display: "show",
          loggedin: localStorage.getItem("appLoggedin"),
          username: userDetailRes.data.username,
          user_first_name: userDetailRes.data.first_name,
          user_last_name: userDetailRes.data.last_name
        });
      } catch (error) {
        // login
        console.log(error);
      }
    })();
  }, []);

  const handleLogout = async () => {
    try {
      setAppState({ ...appState, loggedin: false });
      localStorage.removeItem("appLoggedin");
      await api.logout({});
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert(error.response.data.error);
    }
  };

  const leftPad = {
    marginLeft: '20px',
  };
  const rightPad = {
    marginRight: '20px',
  };
  const bothPad = {
    marginLeft: '20px',
    marginRight: '20px',
  };
  const topPad8 = {
    marginTop: '5px',
  };
  const topPad = {
    marginTop: '20px',
  };
  const buttonStyle = {
    marginTop: '17px',
    marginRight: '17px',
    fontSize: '0.9rem'
  };
  return (
    <div className="App">
      {!appState.loggedin && (
        <Login />
      )}
      {appState.loggedin && (
        <div>
          <MainNavbar
            userFirstName={appState.user_first_name}
            userLastName={appState.user_last_name}
          />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Articles />} />
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}

export default App;
